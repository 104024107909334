import { StatusBar } from 'expo-status-bar';
import React, { useState } from 'react';
import { StyleSheet, View, Text, TextInput, Button, Picker, Alert, ScrollView } from 'react-native';

const makeReadableLongNumber = (numberString) => {
  // Remove any existing non-numeric characters
  const cleanNumberString = numberString.replace(/\D/g, '');

  // Reverse the number string for easier processing
  const reversedNumberString = cleanNumberString.split('').reverse().join('');
  let formattedReversedString = '';

  // Add thousands separators every three digits
  for (let i = 0; i < reversedNumberString.length; i++) {
    if (i > 0 && i % 3 === 0) {
      formattedReversedString += '.';
    }
    formattedReversedString += reversedNumberString[i];
  }

  // Reverse the formatted string back to original order
  const formattedNumberString = formattedReversedString.split('').reverse().join('');

  return formattedNumberString;
};
const parseIntegerFromFormattedString = (formattedString) => {
  // Remove thousands separators (periods) from the string
  const cleanedString = formattedString.replace(/\./g, '');

  // Convert the cleaned string to an integer
  const parsedInteger = parseInt(cleanedString, 10);

  // Return the parsed integer
  return parsedInteger;
};
const App = () => {
  const [mulkTipi, setMulkTipi] = useState('konut');
  const [aylikKira, setAylikKira] = useState('');
  const [vadeFaizi] = useState(15); // Fixed interest rate value
  const [expensiveness, setExpensiveness] = useState(0);
  const [kiraDonemi, setKiraDonemi] = useState(12);
  const [askPrice, setAskPrice] = useState('');
  const [futureValueOfaskPrice, setFutureValueOfaskPrice] = useState(0);
  const [futureValueOfDeposits, setFutureValueOfDeposits] = useState(0);
  const [fairValue, setFairValue] = useState(0);

  const calculateFutureValue = (presentValue, annualInterestRate, years) => {
    const rateDecimal = annualInterestRate / 100;
    return presentValue * Math.pow((1 + rateDecimal), years);
  };

  const calculatePresentValue = (futureValue, annualInterestRate, years) => {
    const rateDecimal = annualInterestRate / 100;
    return futureValue / Math.pow((1 + rateDecimal), years);
  };
  const calculateFutureValueOfDeposits = (monthlyDeposit, annualInterestRate, totalMonths) => {
    // Convert annual interest rate from percentage to a monthly decimal rate
    const monthlyInterestRate = annualInterestRate / 100 / 12;

    // Use the future value of an annuity formula
    const futureValue = monthlyDeposit * ((Math.pow(1 + monthlyInterestRate, totalMonths) - 1) / monthlyInterestRate);

    return futureValue;
  };


  const hesapla = () => {
    if (!mulkTipi || !aylikKira) {
      Alert.alert('Hata', 'Lütfen tüm alanları doldurun.');
      return;
    }

    const aylikKiraInt = parseIntegerFromFormattedString(aylikKira);
    const askPriceInt = parseIntegerFromFormattedString(askPrice);
    const kiraDonemiInt = parseInt(kiraDonemi, 10);
    const yillikKira = aylikKiraInt * kiraDonemiInt;

    const tipCarpani = mulkTipi === 'ticari' ? 4.9 : 8;

    const kiraBasedDeger = yillikKira * tipCarpani;

    const tempFutureValueOfaskPrice = calculateFutureValue(askPriceInt, vadeFaizi, tipCarpani);
    const tempFutureValueOfDeposits = calculateFutureValueOfDeposits(aylikKiraInt, vadeFaizi, tipCarpani * 12);
    const expensiveness = tempFutureValueOfaskPrice - kiraBasedDeger - tempFutureValueOfDeposits;
    const presentValueOfExpensiveness = calculatePresentValue(expensiveness, vadeFaizi, tipCarpani);

    // fair value alırken kirabaseddeğere göre expensiveness hesaplayıp kendisinden çıkarıyoruz. 

    const f_tempFutureValueOfaskPrice = calculateFutureValue(kiraBasedDeger, vadeFaizi, tipCarpani);
    const f_tempFutureValueOfDeposits = calculateFutureValueOfDeposits(aylikKiraInt, vadeFaizi, tipCarpani * 12);
    const f_expensiveness = f_tempFutureValueOfaskPrice - kiraBasedDeger - f_tempFutureValueOfDeposits;
    const f_presentValueOfExpensiveness = calculatePresentValue(f_expensiveness, vadeFaizi, tipCarpani);
    const tempFairValue = kiraBasedDeger - f_presentValueOfExpensiveness;

    setFairValue(Math.round(tempFairValue));
    setFutureValueOfaskPrice(Math.round(tempFutureValueOfaskPrice));
    setFutureValueOfDeposits(Math.round(tempFutureValueOfDeposits));
    setExpensiveness(Math.round(presentValueOfExpensiveness));

  };

  return (
    <View style={{ flex: 1 }}>
      <ScrollView contentContainerStyle={styles.containerWrapper}>
        <View style={styles.container}>
          <StatusBar style="auto" />
          <Text style={styles.header}>Yatırımcılar için Emlak Değer Hesaplayıcı</Text>
          <Picker
            selectedValue={mulkTipi}
            style={styles.picker}
            onValueChange={(itemValue) => setMulkTipi(itemValue)}
          >
            <Picker.Item label="Seçiniz" value="" />
            <Picker.Item label="Konut" value="konut" />
            <Picker.Item label="Ticari" value="ticari" />
          </Picker>
          <TextInput
            style={styles.input}
            placeholder="İstenilen Fiyat"
            keyboardType="numeric"
            value={makeReadableLongNumber(askPrice)}
            onChangeText={(text) => setAskPrice(text)}
          />
          <TextInput
            style={styles.input}
            placeholder="Ortalama Aylık Kira (TL)"
            keyboardType="numeric"
            value={makeReadableLongNumber(aylikKira)}
            onChangeText={(text) => setAylikKira(text)}
          />
          {/* Toplam bir yılda kaç ay kira ödeniyor: kira dönemi */}
          <TextInput
            style={styles.input}
            placeholder="Bir yılda kaç ay kira alınacak?"
            keyboardType="numeric"
            value={kiraDonemi}
            onChangeText={(text) => setKiraDonemi(text)}
          />
          <Button title="Hesapla" onPress={hesapla} />

          <View style={styles.resultContainer}>

            {/* Bugünki yatırımın gelecek değeri */}
            {futureValueOfaskPrice !== 0 && (
              <View>
                <Text style={styles.result}>İstenilen Paranın Yatırım Değeri: {futureValueOfaskPrice.toLocaleString()} TL</Text>
                <Text style={{ fontSize: "0.8em" }}>
                  Bu değer: eğer {makeReadableLongNumber(askPrice)} TL yi <Text style={{ fontWeight: "bold" }}>emlak dışında</Text>, standart yatırım araçlarına yatırırsanız ve ortalama emlak yatırım süresi boyunca beklerseniz; sahip olduğunuz yatırım değerinin bugünkü alım gücünü gösterir.
                </Text>
              </View>
            )}
            {/* Evin kirasının yatırım değeri */}
            {futureValueOfDeposits !== 0 && (
              <View>
                <Text style={styles.result}>Mülkün Kirasının Yatırım Değeri: {futureValueOfDeposits.toLocaleString()} TL</Text>
                <Text style={{ fontSize: "0.8em" }}>Eğer mülkün kirasını ortalama emlak yatırım süresi boyunca standart yatırım araçlarına yatırarak biriktirirseniz, bu sürenin sonunda biriktirdiğiniz paranın bugünkü alım gücünü gösterir.</Text>
              </View>
            )}
            {/* Pahalılık Durumu */}
            {expensiveness !== 0 && (
              <Text style={styles.result}>Mülkün Pahalılık Durumu: {expensiveness.toLocaleString()} TL</Text>
            )}
            {/* evin adil değeri */}
            {fairValue !== 0 && (
              <View>
                <Text style={styles.result}>Mülkün Adil Değeri: {fairValue.toLocaleString()} TL</Text>
                {/* <Text style={{ fontSize: "0.8em" }}>Mülkün adil değeri, yatırımın sağlıklı olduğu tutardır.</Text> */}
              </View>
            )}
            {/* Sorumluluk Reddi */}
            <View>
              <Text style={[styles.result, { color: "red", textAlign: "center" }]}>YASAL UYARI ve SORUMLULUK REDDİ</Text>
              <Text style={[styles.result, { textAlign: "center" }]}>
                Bu sayfada sunulan hesaplamalar ve bilgiler, yatırım tavsiyesi olarak değerlendirilmemelidir. Sağlanan veriler ve araçlar, yalnızca finansal okur yazarlığı artırmak amacıyla örnek bir emlak değeri hesaplama aracı olarak tasarlanmıştır. Yatırım kararlarınızı verirken profesyonel finansal danışmanlık almanız önemlidir. Bu sayfa, herhangi bir yatırım veya finansal karar için kesinlikle bir tavsiye niteliğinde değildir ve herhangi bir yatırım riskini üstlenmez. Bu bilgilere göre yatırım yapmak ciddi zararlar görmenize neden olabilir.
              </Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  containerWrapper: {
    paddingVertical: 50,
    justifyContent: 'center',
    alignItems: 'center', // Center items horizontally
    backgroundColor: '#fff',
  },
  container: {
    marginVertical: 50,
    paddingHorizontal: 20,
    paddingVertical: 10,
    justifyContent: 'center',
    alignItems: 'center', // Center items horizontally
    backgroundColor: '#fff',
    borderWidth: 1,
    borderRadius: 27,
    maxWidth: 300,
  },
  resultContainer: {
    marginTop: 20,

  },
  header: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center',
  },
  picker: {
    height: 50,
    width: '100%',
    marginBottom: 20,
  },
  input: {
    height: 40,
    width: '100%', // Make input fields full width
    borderColor: '#ddd',
    borderWidth: 1,
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  result: {
    marginTop: 20,
    fontSize: 18,
    textAlign: "left",
  },
});

export default App;
